import React from "react";

import { ThemeProvider } from '@emotion/react';
import { theme } from './Theme';

import { Container, CssBaseline } from "@mui/material";

import AppBar from "./components/AppBar";

import './App.css';

// Temporarily stored in file
import { surfcamlist } from "./models/SurfCam";
import Router from "./pages/Router";
import { BrowserRouter } from "react-router-dom";

export default function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Container id="surfcam-app-root-container" sx={{
          marginX: "0px!important",
          paddingX: "0px!important",
          width: "100vw!important",
          minWidth: "100vw!important",
          height: "100vh!important",
          minHeight: "100vh!important",
          overflow: "scroll"
        }}>
          <AppBar />
          <Container id="surfcam-app-contents" sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "top",
            maxWidth: "100vw",
            overflow: "scroll"
          }}>
            <Router surfcams={surfcamlist} />
          </Container>
        </Container>
      </BrowserRouter>
    </ThemeProvider>
  );
}
