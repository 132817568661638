export type SurfCam = {
    beach: string;
    id: string;
    stream: string;
}

export const surfcamlist: SurfCam[] = [
    {
        beach: "North Gong",
        id: "north-wollongong",
        stream: "https://cams.cdn-surfline.com/cdn-au/au-wollongongnorth/chunklist.m3u8"
    } as SurfCam,
    {
        beach: "Thirroul",
        id: "thirroul",
        stream: "https://cams.cdn-surfline.com/cdn-au/au-thirroul/chunklist.m3u8"
    } as SurfCam,
    {
        beach: "Maroubra",
        id: "maroubra",
        stream: "https://cams.cdn-surfline.com/cdn-au/au-maroubra/chunklist.m3u8"
    } as SurfCam,
    {
        beach: "North Maroubra",
        id: "maroubra-north",
        stream: "https://cams.cdn-surfline.com/cdn-au/au-northmaroubra/chunklist.m3u8"
    } as SurfCam,
    {
        beach: "South Maroubra",
        id: "maroubra-south",
        stream: "https://cams.cdn-surfline.com/cdn-au/au-southmaroubra/chunklist.m3u8"
    } as SurfCam,
    // {
    //     beach: "Collaroy",
    //     id: "collaroy",
    //     stream: "https://cams.cdn-surfline.com/cdn-au/au-collaroy/playlist.m3u8"
    // } as SurfCam,
    // {
    //     beach: "Mona Vale",
    //     id: "mona-vale",
    //     stream: "https://cams.cdn-surfline.com/cdn-au/au-monavale/chunklist.m3u8"
    // } as SurfCam,
    // {
    //     beach: "Dee Why",
    //     id: "dee-why",
    //     stream: "https://cams.cdn-surfline.com/cdn-au/au-deewhy/chunklist.m3u8"
    // } as SurfCam,
    // {
    //     beach: "Narrabeen",
    //     id: "narrabeen",
    //     stream: "https://cams.cdn-surfline.com/cdn-au/au-narrabeen/chunklist.m3u8"
    // } as SurfCam,
    // {
    //     beach: "Freshie",
    //     id: "freshie",
    //     stream: "https://cams.cdn-surfline.com/cdn-au/au-freshwater/chunklist.m3u8"
    // } as SurfCam,
    // {
    //     beach: "Palm Beach",
    //     id: "palmy",
    //     stream: "https://cams.cdn-surfline.com/cdn-au/au-palmbeachnsw/chunklist.m3u8"
    // } as SurfCam,
    // {
    //     beach: "Manly North",
    //     id: "manly-north",
    //     stream: "https://cams.cdn-surfline.com/cdn-au/au-manlynorth/chunklist.m3u8"
    // } as SurfCam,
    // {
    //     beach: "Manly South",
    //     id: "manly-south",
    //     stream: "https://cams.cdn-surfline.com/cdn-au/au-manlyhotel/chunklist.m3u8"
    // } as SurfCam,
    // {
    //     beach: "Queenscliff",
    //     id: "queenscliff",
    //     stream: "https://cams.cdn-surfline.com/cdn-au/au-queenscliff/chunklist.m3u8"
    // } as SurfCam
]