import React from "react";

import SurfCamDiv from "../components/SurfCamDiv";

import { SurfCam } from "../models/SurfCam";
import { Container } from "@mui/material";

export type SurfCamListProps = {
    surfcams: SurfCam[]
}

export default function SurfCamList({ surfcams }: SurfCamListProps) {
    return (
        <Container id="surfcam-list-container" sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "top",
            maxWidth: "100vw",
            minHeight: "100%",
            overflow: "scroll",
            marginX: "0px!important",
            paddingX: "0px!important",
            marginBottom: "50px"
        }}>
            {surfcams.map((surfcam) => {
                return (
                    <SurfCamDiv key={surfcam.id} surfcam={surfcam} />
                );
            })}
        </Container>
    )
}