import { Button, Container } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function NotFoundPage() {
    const location = useLocation();
    const [maxHeight, setMaxHeight] = useState<number>(0);
    const textRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (textRef.current) {
            setMaxHeight(textRef.current.clientHeight);
        }
    }, []);

    return (
        <Container id="not-found-content" sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            height: "100%",
            margin: "0px",
            padding: "0px",
        }}>
            <Container id="back-button-container" sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center",
                width: "unset",
                marginTop: "20px",
                marginLeft: "20px",
            }}>
                <Button component={Link} to={'/'} startIcon={<ArrowBackIcon />}>
                    Back to list
                </Button>
            </Container>
            <Container id="not-found-main-content" sx={{
                display: "flex",
                flexDirection: { xl: "row", lg: "row", sm: "row", xs: "column-reverse" },
                justifyContent: "center",
                alignContent: "center",
                width: "unset",
                marginTop: "20px",
            }}>
                <img id="not-found-image"
                    src="/NotFound.png"
                    alt="Not Found Image"
                    style={{
                        maxHeight: `${maxHeight}px`
                    }} />
                <Container id="not-found-text"
                    ref={textRef}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                        justifyContent: "flex-start",
                    }}>
                    <h2>Whoah, bro! Not sure I can find what you're looking for...</h2>
                    <h5>Have you entered the correct URL?</h5>
                    <p>
                        Resource: <code>{location.pathname}</code>
                    </p>
                </Container>
            </Container>
        </Container>
    )
}