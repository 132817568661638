import React, { useRef } from "react";

import ReactHlsPlayer from 'react-hls-player';

import { SurfCam } from "../models/SurfCam";
import { Container } from "@mui/material";

export type CamPlayerProps = {
    surfcam: SurfCam
}

export default function CamPlayer({ surfcam }: CamPlayerProps) {
    const videoRef = useRef<HTMLVideoElement>(null);

    return (
        <Container id={`camplayer-${surfcam.id}`} sx={{
            margin: "0px!important",
            padding: "0px!important",
            display: "flex"
        }}>
            <video ref={videoRef}
                src={surfcam.stream}
                poster="/Tank.gif"
                controls
                autoPlay
                muted
                style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "80vh",
                    outline: "3px solid black",
                    borderRadius: "15px",                    
                }}>
                <ReactHlsPlayer
                    playerRef={videoRef}
                    src={surfcam.stream}
                />
            </video>
        </Container>
    )
}