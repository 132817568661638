import React from "react";

import { Route, Routes } from "react-router-dom";
import { SurfCam } from "../models/SurfCam";

import SurfCamList from "./SurfCamList";
import NotFound from "./NotFound";
import SurfCamDiv from "../components/SurfCamDiv";

export type RouterProps = {
    surfcams: SurfCam[]
}

export default function Router({ surfcams }: RouterProps) {
    return (
        <Routes>
            <Route index element={<SurfCamList surfcams={surfcams} />} />
            {surfcams && surfcams.map((surfcam) => {
                return (
                    <Route key={surfcam.id} path={surfcam.id} element={
                        <SurfCamDiv showBack scrollTop surfcam={surfcam} />
                    } />
                );
            })}
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}