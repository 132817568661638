import { ThemeOptions, createTheme } from '@mui/material/styles';

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#2DA0D8',
    },
    secondary: {
      main: '#de65fc',
    },
    background: {
      default: "#fef4ea",
    }
  },
  mixins: {
    toolbar: {
      minHeight: 70,
    },
  }
};

export const theme = createTheme(themeOptions);