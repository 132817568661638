import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import { SurfCam } from "../models/SurfCam";
import { Button, Container, Typography, useMediaQuery } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

import CamPlayer from "./CamPlayer";

export type SurfCamDivProps = {
    surfcam: SurfCam,
    showBack?: boolean,
    scrollTop?: boolean
}

export default function SurfCamDiv({ surfcam, showBack, scrollTop }: SurfCamDivProps) {
    const screenIsWide = useMediaQuery('(min-width:850px)');

    return (
        <Container id={`surfcam-div-${surfcam.id}`} sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "25px",
            height: "100%"
        }}>
            {showBack &&
                <Container id="button-container" sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "left",
                    width: "100%"
                }}>
                    <Button component={Link} to={'/'} startIcon={<ArrowBackIcon />}>
                        Back to list
                    </Button>
                </Container>
            }
            <Typography variant={screenIsWide ? "h1" : "h3"}
                noWrap
                component={Link}
                to={`/${surfcam.id}`}
                sx={{
                    fontFamily: "Bradley Hand, sans-serif",
                    fontWeight: 700,
                    paddingBottom: "10px",
                    textDecoration: "none",
                    color: "#323232",
                }}>
                {surfcam.beach}
                {!showBack &&
                    <ArrowOutwardIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                }
            </Typography>
            <CamPlayer surfcam={surfcam} />
        </Container>
    )
}