import React from 'react';

import { Link } from 'react-router-dom';

import { Box, Toolbar, Typography, useMediaQuery } from '@mui/material';

import MuiAppBar from '@mui/material/AppBar';

export default function AppBar() {
    const screenIsWide = useMediaQuery('(min-width:880px)');

    return (
        <MuiAppBar id="surfcam-app-bar" sx={{
            flexGrow: 1,
            position: "sticky",
            zIndex: (theme) => theme.zIndex.drawer + 1
        }}>
            <Toolbar id="surfcam-app-bar-tool-bar" sx={{
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "center"
            }}>
                <Link to="/">
                    <Box id="surfcam-logo-box"
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            justifyContent: "center",
                            margin: "10px"
                        }}>
                        <img src="/Chicken_Joe.png" alt="SurfCam Logo" width={screenIsWide ? "100px" : "50px"} />
                    </Box>
                </Link>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <Typography variant="h1" sx={{
                        fontFamily: "Bradley Hand, sans-serif",
                        fontWeight: 700,
                        fontSize: { lg: "3.3rem", md: "2.8rem", sm: "1.8rem", xs: "1.15rem"},
                        margin: "4px",
                        color: "#ffe9d4",
                        textShadow: "2px 2px 2px black"
                    }}>
                        Chicken Joe's Bootleg SurfCams
                    </Typography>
                </Link>
            </Toolbar>
        </MuiAppBar>
    );
}